'use strict';

angular.module('enervexSalesappApp').factory('Image', function ($resource) {
	return $resource('/api/images/:id', {
		id: '@_id'
	},
	{
		save: {
			method: 'POST',
			transformRequest: angular.identity,
			headers: {
				'Content-Type': undefined,
				'Accept': "application/json"
			}
		} ,
		update: {
			method: 'PUT',
			transformRequest: angular.identity,
			headers: {
				'Content-Type': undefined,
				'Accept': "application/json"
			}
		}
	});
});
